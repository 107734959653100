import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home.jsx';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/launchpad/:networkValue/:dynamicValue" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
